<template>
  <div class="flex-grow">
    <div class="flex items-center justify-center h-full">
      <Form @submit="handleResetpassword" :validation-schema="schema">
        <div class="m-auto pb-2 pt-1 px-4 bg-green-400 rounded-3xl" style="min-width: 24rem">
          <div class="card-body">
            <center>
              <h3>รหัสผ่านใหม่</h3>
              <p>กรุณาระบุรหัสผ่านใหม่</p>
            </center>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">ชื่อผู้ใช้</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext" id="username" value="xxx" />
              </div>
            </div>
            <Field name="new_password" type="password" class="form-control" placeholder="รหัสผ่าน" />
            <ErrorMessage name="new_password" class="error-feedback" />
            <p>
              - ต้องมีความยาวอย่างน้อย 6 ตัว<br />
              - ต้องประกอบไปด้วยตัวอักษรและตัวเลข
            </p>
            <Field name="confirm_new_password" type="password" class="form-control" placeholder="ยืนยันรหัสผ่านอีกครั้ง" />
            <ErrorMessage name="confirm_new_password" class="error-feedback" />
            <br />
            <button class="btn btn-secondary col-sm-12" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span>ยืนยันการเปลี่ยนแปลงรหัสผ่าน</span>
            </button>
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
export default {
  name: 'reset-password',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      new_password: yup.string().required('new password is required!'),
      confirm_new_password: yup.string().required('confirm new password is required!')
    })

    return {
      loading: false,
      message: '',
      schema
    }
  },
  methods: {
    handleResetpassword(user) {
      this.loading = true

      this.$store.dispatch('auth/resetpassword', user).then(
        () => {
          this.$router.push('/login')
        },
        (error) => {
          this.loading = false
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      )
    }
  }
}
</script>

<style scoped>
h3 {
  color: #fff;
}
.row,
.row input {
  color: #fff;
}
</style>